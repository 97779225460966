
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/presentes/[listLink]",
      function () {
        return require("private-next-pages/presentes/[listLink]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/presentes/[listLink]"])
      });
    }
  