import { GetServerSideProps } from "next";
import { cookie, weddingGiftListApi } from "implementations";
import { getIsListInactive } from "application";
import GiftsList from "../../../src/screens/GiftsList";

type GiftsListProps = {
  weddingListLink: string;
  weddingListId: number;
  isListInactive: boolean;
};

export const getServerSideProps: GetServerSideProps<GiftsListProps> = async ({
  req,
  res,
  query,
}) => {
  const weddingListLink = query?.listLink as unknown as string;
  const accessToken = cookie.getCookie({
    name: "@FC:Ecom:Token:Access",
    options: { req, res },
  });

  const { data: weddingListByLink } = await weddingGiftListApi.getIdByListLink(
    weddingListLink as string,
    !!weddingListLink
  );

  const { data: weddingListById } = await weddingGiftListApi.getWeddingList(
    accessToken,
    weddingListByLink?.id
  );

  const weddingListId = weddingListByLink?.id;

  if (!weddingListId) {
    return {
      notFound: true,
    };
  }

  const isListInactive = getIsListInactive(weddingListById);

  return {
    props: { weddingListLink, isListInactive, weddingListId },
  };
};

export default GiftsList;
